const firebaseConfig = {
  apiKey: "AIzaSyCBl45lvpJ-MGXHsSgZZgzSzV32AnUHl2E",
  authDomain: "neonify-prod.firebaseapp.com",
  projectId: "neonify-prod",
  storageBucket: "neonify-prod.appspot.com",
  messagingSenderId: "335175182822",
  appId: "1:335175182822:web:fcc15b2e3e06f886d08e27",
  measurementId: "G-5CWBVLQSR2",
};

export default firebaseConfig;
