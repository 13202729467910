import React from "react";
import { Button, Grid, Card } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddFreeUpgradePopUp({ isOpen, closeTrialDialog }) {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeTrialDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Get your free 30 days trial now!!"}</DialogTitle>

      <Grid item>
        <Card elevation={6} className="pricing__card text-center p-sm-24">
          <img
            className="mb-16"
            src="/assets/images/illustrations/graph.svg"
            alt="upgrade"
            style={{ height: "90px" }}
          />
          <div className="mb-16">
            {/* <h2>Pro</h2> */}
            <h2>Basic</h2>
          </div>

          <div className="mb-24">
            {/* <p className="mt-0">2,000 Searches</p>
            <p>7 custom data pulls</p>
            <p className="mb-0">15 data connections to supported URL's</p>
            <p className="mb-0">Custom website plugin</p>
            <p className="mb-0">
              Access to our dedicated email and live chat support team
            </p>
            <p>10 hours of client management per month</p> */}

            <p>Access 5 supported API endpoints</p>
          </div>

          <Button variant="contained" color="primary" className="uppercase">
            Accept
          </Button>
          <Button className="uppercase ml-16" onClick={closeTrialDialog}>
            Close
          </Button>
        </Card>
      </Grid>
    </Dialog>
  );
}

export default AddFreeUpgradePopUp;

AddFreeUpgradePopUp.propTypes = {
  isOpen: PropTypes.bool,
  closeTrialDialog: PropTypes.func.isRequired,
};
