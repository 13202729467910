import axios from "axios";
import Config from '../../config/Config'

const { serverUrl } = Config

const registerUser = async (formData) => {
  try {
    const response = await axios.post(`${serverUrl}/api/users/register`, { 'name': formData.name, 'email': formData.email, 'password': formData.password, 'phone_number': formData.phone_number, 'company_name': formData.company_name, 'is_admin': formData.is_admin })
    return response
  } catch (err) {
    return err.response
  }

}

const getAllUsersInfo = async () => {
  try {
    const response = await axios.get(`${serverUrl}/api/users/all`)
    return response
  } catch (err) {
    return err.response
  }
}

const deleteUserWithID = async (id) => {
  try {
    const response = await axios.delete(`${serverUrl}/api/users/${id}`)
    return response
  } catch (err) {
    return err.response
  }
}

const getUserDetailsbyID = async (id) => {
  try {
    const response = await axios.get(`${serverUrl}/api/users/single/${id}`)
    return response
  } catch (err) {
    return err.response
  }
}


const updateUserWithID = async (data) => {
  try {
    const { id, formData } = data
    const response = await axios.put(`${serverUrl}/api/users/${id}`, { name: formData.name, email: formData.email, phone_number: formData.phone_number, company_name: formData.company_name, is_active: formData.is_active, is_admin: formData.is_admin })
    return response
  } catch (err) {
    return err.response
  }
}

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${serverUrl}/api/users/forgotpassword`, { 'email': email })
    return response
  } catch (err) {
    return err.response
  }
}

export {
  registerUser,
  getAllUsersInfo,
  deleteUserWithID,
  getUserDetailsbyID,
  updateUserWithID,
  forgotPassword
}