import React from "react";
import {
  Card,
  Grid,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import SigninSecond from "./SigninSecond";

const useStyles = makeStyles({
  main: {
    paddingTop: "8rem",
    paddingBottom: "8rem",
  },
  heading: {
    marginBottom: 20,
    fontSize: 60,
    fontWeight: 700,
    marginBottom: 15,
  },
  p: {
    fontSize: 20,
    fontWeight: 200,
    marginBottom: 12,
  },
  button: {
    marginTop: 15,
    padding: "10px, 30px, 15px",
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 18,
    textDecoration: "none",
  },
  grid2: {
    border: "1px solid black",
  },
});

export default function SessionLayout({ children }) {
  const classe = useStyles();

  return (
    <div>
      <Container className={classe.main}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item sm={6}>
            <Typography className={classe.heading} variant="h1">
              Welcome to Neonify
            </Typography>
            <Typography className={classe.p}>
              A revolutionary new proprietary AdTech platform that will enrich
              websites with enhanced content, enable publishers to accelerate
              site traffic and drive revenue for content creators.
            </Typography>
            <Typography className={classe.p}>
              Targeted at publishers and brands with libraries of unique content
              that have both an on and offline presence.
            </Typography>
            <Typography className={classe.p}>
              Our technology draws in targeted and relevant pre-selected content
              from across the web via our platform to your site, which improves
              customer engagement, attracts new customers, dramatically improves
              your site traffic and organic search engine optimisation.
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Card elevation={0} className={classe.grid2}>
              {children}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <SigninSecond />
    </div>
  );
}
