import React from "react";
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const ManageSocialURLS = EgretLoadable({
  loader: () => import("../index.jsx"),
});

const URLS = [
  {
    path: "/socialurls",
    exact: true,
    render: (props) =>
      localStorage.getItem("token") ? (
        <ManageSocialURLS />
      ) : (
        <Redirect to="/session/signin" />
      ),
  },
];

export default URLS;
