import React, { useState, useContext } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  makeStyles,
  Typography,
  Box,
  CardHeader,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { userLogin } from "./userService";
import { toast } from "react-toastify";
import setAuthToken from "app/utils/setAuthToken";
import AppContext from "app/appContext";
import { Link } from "react-router-dom";
import SigninSecond from "./SigninSecond";
import SessionLayout from "./SessionLayout";

const useStyles = makeStyles({
  main: {
    paddingLeft: 60,
    paddingRight: 30,
    paddingTop: 40,
  },
  heading: {
    marginBottom: 20,
    fontSize: 60,
    fontWeight: 700,
    marginBottom: 15,
  },
  p: {
    fontSize: 20,
    fontWeight: 200,
    marginBottom: 12,
  },
  button: {
    marginTop: 15,
    padding: "10px, 30px, 15px",
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 18,
    textDecoration: "none",
  },
  grid2: {
    padding: -30,
    width: 350,
    marginTop: 180,
  },
});

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SignIn = (props) => {
  const { setUser } = useContext(AppContext);

  let { classes } = props;

  const initialState = { email: "", password: "" };
  const [formData, setFormData] = useState(initialState);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const response = await userLogin(formData);
    console.log(response);
    if (response && response.status === 200) {
      const {
        data: {
          data: { jwt_token, userDetails },
        },
      } = response;
      setUser(userDetails);
      localStorage.setItem("token", jwt_token);
      setAuthToken(localStorage.getItem("token"));
      setFormData({ ...formData, email: "", password: "" });
      // props.history.push({
      //   pathname: "/properties/select",
      //   state: { path: "/find" },
      // });
      props.history.push({
        pathname: "/home",
        // state: { path: "/find" },
      });
    } else {
      const data = response && response.data;
      const msg = data && data.message;
      const message = msg || "Something went wrong";
      toast.error(message);
    }
  };

  const onFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <SessionLayout>
      <div className="p-36 position-relative">
        <CardHeader title="Sign In" style={{ paddingLeft: 0 }} />
        <ValidatorForm onSubmit={onFormSubmit}>
          <TextValidator
            className="mb-24 w-100"
            variant="outlined"
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            onChange={onFieldChange}
          />
          <TextValidator
            className="mb-16 w-100"
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            value={formData.password}
            validators={["required"]}
            errorMessages={["this field is required"]}
            onChange={onFieldChange}
          />
          <p>
            <span>
              Don't have an account?{" "}
              <Link
                to="/session/signup"
                style={{ textDecoration: "underline" }}
              >
                Sign Up
              </Link>
            </span>
          </p>
          <div className="flex mb-8 flex-middle">
            <div className={classes.wrapper}>
              <Button variant="contained" color="primary" type="submit">
                Sign in
              </Button>
            </div>
          </div>
          <Button
            className="text-primary"
            onClick={() => props.history.push("/session/forgot-password")}
          >
            Forgot password?
          </Button>
        </ValidatorForm>
      </div>
    </SessionLayout>
  );
};

export default withStyles(styles, { withTheme: true })(SignIn);
