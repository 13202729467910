import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button } from "@material-ui/core";
import Config from "app/config/Config";
import BillingDetailsFields from "./BillingDetailsField";
import styled from "@emotion/styled";
import Row from "./Row";
import { useContext } from "react";
import AppContext from "app/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = ({ price, plan }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const history = useHistory();

  const { loggedInUser, setUser } = useContext(AppContext);

  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      const paymentIntent = await fetch(
        `${Config.serverUrl}/api/payment/payment-intent`,
        { method: "POST", headers, body: JSON.stringify({ price }) }
      );

      const { client_secret: clientSecret } = await paymentIntent.json();

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      await fetch(`${Config.serverUrl}/api/payment/create-transaction`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          user: loggedInUser.id,
          amount: price,
          transactionId: paymentMethodReq.paymentMethod.id,
          plan,
        }),
      });

      toast.success("Plan upgraded!");

      const response = await axios.get(`${Config.serverUrl}/api/users`);
      if (response.status === 200) {
        const {
          data: { user },
        } = response;
        setUser(user);
      }

      history.push("/properties/select");
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const iframeStyles = {
    base: {
      color: "#000",
      fontSize: "16px",
      iconColor: "#000",
      "::placeholder": {
        color: "#495057",
      },
    },
    invalid: {
      iconColor: "#a4161a",
      color: "#a4161a",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <BillingDetailsFields />
      </Row>

      <Row>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>

      <Row>
        <Button
          className="capitalize"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? "Processing..." : `Pay $${price}`}
        </Button>
      </Row>
      {/* Show error message to your customers */}
      {checkoutError && <div>{checkoutError}</div>}
    </form>
  );
};

export default CheckoutForm;
