import React, { useState } from "react";
import { Breadcrumb } from "egret";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, TextareaAutosize, Grid } from "@material-ui/core";
import { sendContactDetailsThroughMail } from "./contactService";
import { toast } from "react-toastify";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    message: "",
    subject: "",
  });

  const { fullname, email, message, subject } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await sendContactDetailsThroughMail(formData);
    if (response.status === 200) {
      const successMessage = response.data.message;
      setFormData({
        ...formData,
        fullname: "",
        email: "",
        message: "",
        subject: "",
      });
      toast.success(successMessage);
    } else {
      toast.error("Failed to send Message");
    }
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb
          routeSegments={[
            { name: "Contact Us", path: "/contact" },
            { name: " Citrus Orange Team" },
          ]}
        />
      </div>
      <div>
        <ValidatorForm onSubmit={handleSubmit} onError={(errors) => null}>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextValidator
                className="mb-16 w-100"
                label="Your Full Name"
                onChange={handleChange}
                type="text"
                name="fullname"
                value={fullname}
                validators={[
                  "required",
                  "minStringLength: 4",
                  "maxStringLength: 15",
                ]}
                errorMessages={["this field is required"]}
              />

              <TextValidator
                className="mb-16 w-100"
                label="Your Email"
                onChange={handleChange}
                type="email"
                name="email"
                value={email}
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
              />

              <TextValidator
                className="mb-16 w-100"
                label="Subject"
                onChange={handleChange}
                type="text"
                name="subject"
                value={subject}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />

              <TextValidator
                className="mb-16 w-100"
                label="Query/Message"
                onChange={handleChange}
                type="text"
                name="message"
                value={message}
                validators={["required"]}
                errorMessages={["this field is required"]}
                multiline
                rows={3}
                rowsMax={Infinity}
              />
              {/* <TextareaAutosize
                                className="mb-16 w-100"
                                aria-label="minimum height"
                                maxRows={4}
                                placeholder="Query/Message"
                                type="text"
                                name="message"
                                value={message}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                style={{ height: '100px' }}
                            /> */}
            </Grid>
          </Grid>
          <Button color="primary" variant="contained" type="submit">
            <span className="pl-8 capitalize">Send</span>
          </Button>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default Contact;
