import React, { useState, useContext } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  makeStyles,
  Typography,
  Box,
  CardMedia,
  Container,
  Divider,
} from "@material-ui/core";
import Done from "@material-ui/icons/Done";
import Hand from "./hand.jpg";
import Neonify from "./neonify2.jpg";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#ff9e43",

    paddingTop: "8rem",
    paddingBottom: "8rem",
  },
  main: {
    paddingLeft: 60,
    paddingRight: 30,
    paddingTop: 40,
  },
  heading: {
    fontWeight: 700,
  },
  p: {
    fontSize: 18,
  },
  button: {
    marginTop: 15,
    padding: "10px, 30px, 15px",
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 18,
    textDecoration: "none",
  },
  secondSection: {
    marginTop: 30,
  },
});

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SignInSecond = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <Grid container direction="row" alignItems="center" style={{}}>
          <Grid item sm={6}>
            <Card>
              <CardMedia
                component="img"
                height="auto"
                image={Hand}
                alt="neonify hand"
              />
            </Card>
          </Grid>
          <Grid item className={classes.main} sm={6}>
            <Typography className={classes.heading} variant="h4" gutterBottom>
              What Neonify Does?
            </Typography>
            <Typography className={classes.p}>
              Our web data extractor technology draws in targeted and relevant
              pre-selected content from across the web via our platform to your
              site using web data crawlers, which improves customer engagement,
              attracts new customers, dramatically improves your site traffic
              and organic search engine optimisation.
            </Typography>
            <Typography className={classes.p}>
              Neonify, helps when you need to manage and reduce content creation
              costs and benefits those that have both and/or an offline
              presence.
            </Typography>
            <Typography className={classes.p}>
              <Done /> Extract public data in-app as well as websites
            </Typography>
            <Typography className={classes.p}>
              <Done /> Neonify Web Scraper API can integrate directly with any
              WordPress (or any custom CMS web scraping) site for data gathering
            </Typography>
            <Typography className={classes.p}>
              <Done /> Real-time data collection & Publishing according to
              predefined custom templates and the format of collected data can
              be JSON, CSV, HTML, or Microsoft Excel.
            </Typography>
            <Typography className={classes.p}>
              <Done /> Has the full reach of Google. (Google and YouTube API’s)
            </Typography>
            <Typography className={classes.p}>
              <Done /> Helps content generation using commercial web crawling
            </Typography>
          </Grid>
        </Grid>

        <Divider
          style={{
            color: "#000",
            height: "2px",
            marginBottom: "6rem",
            marginTop: "6rem",
          }}
        />

        <Grid
          className={classes.secondSection}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item className={classes.main} sm={6}>
            <Typography className={classes.heading} variant="h4" gutterBottom>
              The problem we solve
            </Typography>
            <Typography className={classes.p}>
              Some of the biggest challenges for publishers, brands and website
              owners is:
            </Typography>
            <Typography className={classes.p}>
              <Done /> Content creation, design
            </Typography>
            <Typography className={classes.p}>
              <Done /> Finding rich content sources and links
            </Typography>
            <Typography className={classes.p}>
              <Done /> The average cost for a single page of content is around
              £100 per page on a website.
            </Typography>
            <Typography className={classes.p}>
              <Done /> An Enterprise subscription will create 10,000 pages at a
              fraction of that price per page
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Card>
              <CardMedia
                component="img"
                height="auto"
                image={Neonify}
                alt="neonify"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SignInSecond);
