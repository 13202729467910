import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const Home = EgretLoadable({
  loader: () => import("./Home")
});


const homeRoutes = [
  {
    path: "/home",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <Home /> : <Redirect to='/session/signin' />
  },
  
];

export default homeRoutes;
