import React, { useState } from "react";
import {
    Card,
    Grid,
    Button,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { resetPassword } from './userService'


const ForgotPassword = (props) => {

    const [formData, setFormData] = useState({
        token: props.match.params.token,
        newpassword: '',
        confirmpassword: ''
    })

    const { token, newpassword, confirmpassword } = formData

    const onFormSubmit = async (e) => {
        e.preventDefault()
        if (newpassword === confirmpassword) {
            const response = await resetPassword(formData)
            if (response.status === 200) {
                toast.success(response.data.message)
                setFormData({ ...formData, token: '', newpassword: '', confirmpassword: '' })
                props.history.push("/session/signin")
            }
            else {
                const errorMessage = response.data.message
                toast.error(errorMessage)
            }
        }
        else {
            toast.error('New Password and Confirm Password dont match ')
        }
    }

    const onFieldChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    return (
        <div className="signup flex flex-center w-100 h-100vh">
            <div className="p-8">
                <Card className="signup-card position-relative y-center">
                    <Grid container>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <div className="p-32 flex flex-center flex-middle h-100">
                                <img src="/assets/images/illustrations/neon.png" alt="neon-accelerator-image" />
                            </div>
                        </Grid>
                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <div className="p-36 h-100 bg-light-gray position-relative">
                                <ValidatorForm onSubmit={onFormSubmit}>
                                    <TextValidator
                                        className="mb-24 w-100"
                                        variant="outlined"
                                        label="New Password"
                                        onChange={onFieldChange}
                                        type="password"
                                        name="newpassword"
                                        value={newpassword}
                                        validators={["required"]}
                                        errorMessages={[
                                            "this field is required",
                                        ]}
                                    />

                                    <TextValidator
                                        className="mb-24 w-100"
                                        variant="outlined"
                                        label="Confirm New Password"
                                        onChange={onFieldChange}
                                        type="password"
                                        name="confirmpassword"
                                        value={confirmpassword}
                                        validators={["required"]}
                                        errorMessages={[
                                            "this field is required",

                                        ]}
                                    />
                                    <div className="flex flex-middle">
                                        <Button variant="contained" color="primary" type="submit">
                                            Reset Password
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </div>
    );
}

export default ForgotPassword