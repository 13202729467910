import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import Console from "utils/Console";
import firebaseConfig from "./firebaseConfig";

class FirebaseAuthService {
  instance;
  auth;
  firestore;
  //   database;
  //   storage;

  googleProvider;
  facebookProvider;
  twitterProvider;

  constructor() {
    this.init();
    this.auth = firebase.auth();

    // this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider().addScope(
      "pages_read_engagement,pages_manage_posts,instagram_basic,instagram_content_publish"
    );
    // this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  static getInstance = () => {
    if (!this.instance) this.instance = new FirebaseAuthService();
    return this.instance;
  };

  init = () => {
    firebase.initializeApp(firebaseConfig);
  };

  checkAuthStatus = (callback) => {
    this.auth.onAuthStateChanged(callback);
  };

  signUpWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  signInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  signInWithPopup = (media) => {
    switch (media) {
      case "google":
        return this.auth.signInWithPopup(this.googleProvider);

      case "facebook":
        return this.auth.signInWithPopup(this.facebookProvider);

      case "twitter":
        return this.auth.signInWithPopup(this.twitterProvider);

      default:
        break;
    }
  };

  signInWithPhoneNumber = (phoneNumber) => {
    return this.auth.signInWithPhoneNumber(phoneNumber);
  };

  getUserData = (docId) => {
    //   generally it's better to use uid for docId
    this.firestore
      .collection("users")
      .doc(docId)
      .get()
      .then((doc) => {
        Console.log(doc.data());
      });
  };

  getAllUser = () => {
    this.firestore
      .collection("users")
      .get()
      .then((docList) => {
        docList.forEach((doc) => {
          Console.log(doc.data());
        });
      });
  };

  signOut = () => {
    return this.auth.signOut();
  };

  addScopes = (providerSocialName, scopes) => {
    if (providerSocialName === "facebook" && this.facebookProvider) {
      scopes.forEach((scope) => this.facebookProvider.addScope(scope));
    }
  };
}

const firebaseAuthServce = FirebaseAuthService.getInstance();

export default firebaseAuthServce;
