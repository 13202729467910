import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const Find = EgretLoadable({
    loader: () => import('./goFind')
})

const goFindRoutes = [
    {
        path: '/find',
        exact: true,
        render: (props) => localStorage.getItem('token') ? <Find /> : <Redirect to="/session/signin" />

    }
]

export default goFindRoutes;