import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";


const PullDataInfo = EgretLoadable({
    loader: () => import("./PullDataInfo")
});

const OutputRoutes = [
    {
        path: "/output",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <PullDataInfo /> : <Redirect to='/session/signin' />
    },
]

export default OutputRoutes