import axios from 'axios'
import Config from '../../config/Config'
const { serverUrl } = Config

const sendContactDetailsThroughMail = async (data) => {
    try {
        console.log({ data })
        const response = await axios.post(`${serverUrl}/contact`, { data })
        return response
    } catch (err) {
        console.log(err)
        return err.response
    }
}

export { sendContactDetailsThroughMail }