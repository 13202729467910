import React, { useState } from "react";
import {
  Card,
  Grid,
  Button,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { forgotPassword } from './userService'
import { toast } from "react-toastify"; import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ForgotPassword = (props) => {

  const [formData, setFormData] = useState({
    email: '',
    token: ''
  })
  const [showMessage, setShowMessage] = useState(false)
  const { email, token } = formData

  const onFormSubmit = async (e) => {
    e.preventDefault()
    const response = await forgotPassword(formData)

    if (response.status === 200) {
      const { data: { data: { reset_password_token } } } = response
      setFormData({ ...formData, token: reset_password_token })
      setShowMessage(true)
    }
    else {
      const errorMessage = response.data.message
      toast.error(errorMessage)
    }

  }

  const onChangeField = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle h-100">
                <img src="/assets/images/illustrations/neon.png" alt="neon-accelerator-image" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              {
                showMessage === true ? (
                  <div className="p-36 h-100 bg-light-gray position-relative">
                    <Alert severity="info">Sent you Mail! Please check!</Alert>
                  </div>) : (
                  <div className="p-36 h-100 bg-light-gray position-relative">
                    <ValidatorForm onSubmit={onFormSubmit}>
                      <TextValidator
                        className="mb-24 w-100"
                        variant="outlined"
                        label="Email"
                        onChange={onChangeField}
                        type="email"
                        name="email"
                        value={email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid"
                        ]}
                      />
                      <div className="flex flex-middle">
                        <Button variant="contained" color="primary" type="submit">
                          Send Mail
                        </Button>
                        <span className="ml-16 mr-8">or</span>
                        <Button
                          className="capitalize"
                          onClick={() =>
                            props.history.push("/session/signin")
                          }
                        >
                          Sign in
                        </Button>
                      </div>
                    </ValidatorForm>
                  </div>)
              }
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default ForgotPassword