import React from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { useState } from "react";
import AppContext from "../../appContext";
import Tooltip from "@material-ui/core/Tooltip";

// import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useContext } from "react";
import PaymentPopUp from "../components/PaymentPopup";
import AddFreeUpgradePopUp from "../components/AddFreeUpgradePopUp";

const PricePage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [plan, setPlan] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const user = useContext(AppContext);

  const makePayment = async (plan, amount) => {
    setSelectedPrice(amount);
    setPlan(plan);
    setOpenModal(true);
  };

  const openTrialDialog = () => setShowDialog(true);
  const closeTrialDialog = () => setShowDialog(false);

  return (
    <div className="pricing m-sm-30 position-relative">
      <div className="w-100 text-center mb-44">
        <h3 className="m-0 font-weight-500">
          Choose the plan that's right for you!
        </h3>
      </div>

      <div>
        <Grid container spacing={6}>
          <Grid item lg={4} md={5} sm={5} xs={12}>
            <Card elevation={6} className="pricing__card text-center p-sm-24">
              <img
                className="mb-16"
                src="/assets/images/illustrations/baby.svg"
                alt="upgrade"
              />
              <div className="mb-16">
                <h5>Basic</h5>
                {/* <h1>$75</h1>
                  <small className="text-muted">Monthly</small> */}
              </div>

              <div className="mb-24">
                <p className="mt-0">Access 5 supported API endpoints</p>
                {/* <Tooltip title="what is a supported URL? - a supported URL is one of the many sites we have an existing data extraction connection with">
                  <p>Look at up to 5 supported URL's</p>
                </Tooltip> */}
              </div>

              <Button
                variant="contained"
                color="primary"
                className="uppercase"
                onClick={openTrialDialog}
              >
                Free 30 day trial
              </Button>
            </Card>
          </Grid>
          <Grid item lg={4} md={5} sm={5} xs={12}>
            <Card elevation={6} className="pricing__card text-center p-sm-24">
              <img
                className="mb-16"
                src="/assets/images/illustrations/single.png"
                alt="single"
              />
              <div className="mb-16">
                <h5>Single</h5>
                {/* <h1>$195</h1>
                  <small className="text-muted">Monthly</small> */}
              </div>

              <div className="mb-24">
                <Tooltip title="what is custom data pull? - a   custom data pull a fresh custom data extraction set up that is requested via the 'Go Find' section or 'Supported URL' section">
                  <p>Single Custom Data Pull</p>
                </Tooltip>
                <p> Up to 5MB of data usage per day</p>
                <Tooltip title="what is a supported URL? - a supported URL is one of the many sites we have an existing data extraction connection with">
                  <p className="mb-0">5 data connections to supported URL's</p>
                </Tooltip>
                <p className="mb-0">
                  Access to our dedicated email and live chat support team
                </p>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="uppercase"
                disabled={user?.loggedInUser?.plan === "single"}
                onClick={() => makePayment("single", 179)}
              >
                £179 per month
              </Button>
              {/* </StripeCheckout> */}
            </Card>
          </Grid>
          <Grid item lg={4} md={5} sm={5} xs={12}>
            <Card elevation={6} className="pricing__card text-center p-sm-24">
              <img
                className="mb-16"
                src="/assets/images/illustrations/upgrade.svg"
                alt="upgrade"
              />
              <div className="mb-16">
                <h5>Standard</h5>
                {/* <h1>$195</h1>
                  <small className="text-muted">Monthly</small> */}
              </div>

              <div className="mb-24">
                <p className="mt-0">1,000 Searches</p>
                <Tooltip title="what is custom data pull? - a   custom data pull a fresh custom data extraction set up that is requested via the 'Go Find' section or 'Supported URL' section">
                  <p>3 custom data pulls</p>
                </Tooltip>
                <p> Up to 10MB of data usage per day</p>
                <Tooltip title="what is a supported URL? - a supported URL is one of the many sites we have an existing data extraction connection with">
                  <p className="mb-0">5 data connections to supported URL's</p>
                </Tooltip>
                <p className="mb-0">
                  Access to our dedicated email and live chat support team
                </p>
              </div>

              {/* <StripeCheckout
                stripeKey={Config.stripePublicKey}
                token={(token) => makePayment(token, "standard", 399)}
                amount={399 * 100}
                name="Standard Plan"
                currency="gbp"
              > */}
              <Button
                variant="contained"
                color="primary"
                className="uppercase"
                disabled={user?.loggedInUser?.plan === "standard"}
                onClick={() => makePayment("standard", 399)}
              >
                £399 per month
              </Button>
              {/* </StripeCheckout> */}
            </Card>
          </Grid>
          <Grid item lg={4} md={5} sm={5} xs={12}>
            <Card elevation={6} className="pricing__card text-center p-sm-24">
              <img
                className="mb-16"
                src="/assets/images/illustrations/graph.svg"
                alt="upgrade"
              />
              <div className="mb-16">
                <h5>Pro</h5>
                {/* <h1>$195</h1>
                  <small className="text-muted">Monthly</small> */}
              </div>

              <div className="mb-24">
                <p className="mt-0">2,000 Searches</p>
                <Tooltip title="what is custom data pull? - a   custom data pull a fresh custom data extraction set up that is requested via the 'Go Find' section or 'Supported URL' section">
                  <p>7 custom data pulls</p>
                </Tooltip>
                <p> Up to 50MB of data usage per day</p>
                <Tooltip title="what is a supported URL? - a supported URL is one of the many sites we have an existing data extraction connection with">
                  <p className="mb-0">15 data connections to supported URL's</p>
                </Tooltip>
                <p className="mb-0">Custom website plugin</p>
                <p className="mb-0">
                  Access to our dedicated email and live chat support team
                </p>
                <p>10 hours of client management per month</p>
              </div>

              <Button
                variant="contained"
                color="primary"
                className="uppercase"
                disabled={user?.loggedInUser?.plan === "pro"}
                onClick={() => makePayment("pro", 799)}
              >
                £699 per month
              </Button>
            </Card>
          </Grid>
          <Grid item lg={4} md={5} sm={5} xs={12}>
            <Card elevation={6} className="pricing__card text-center p-sm-24">
              <img
                className="mb-16"
                src="/assets/images/illustrations/business_deal.svg"
                alt="upgrade"
              />
              <div className="mb-16">
                <h5>Enterprise</h5>
                {/* <h1>$495</h1>
                  <small className="text-muted">Monthly</small> */}
              </div>

              <div className="mb-24">
                <p className="mt-0">5,000 Searches</p>
                <Tooltip title="what is custom data pull? - a   custom data pull a fresh custom data extraction set up that is requested via the 'Go Find' section or 'Supported URL' section">
                  <p>15 custom data pulls</p>
                </Tooltip>
                <p>Up to 100MB of data usage per day</p>
                <Tooltip title="what is a supported URL? - a supported URL is one of the many sites we have an existing data extraction connection with">
                  <p className="mb-0">
                    Unlimited data connections to supported URL's
                  </p>
                </Tooltip>

                <p className="mb-0">Custom website plugin</p>
                <p className="mb-0">
                  Access to our dedicated email and live chat support team
                </p>
                <p className="mt-0">Scrapped Data on Regular basis</p>
                <p>Full client management per month</p>
              </div>

              <Button variant="contained" color="primary" className="uppercase">
                £1299 per month
              </Button>
            </Card>
          </Grid>
        </Grid>
      </div>

      {openModal && (
        <PaymentPopUp openModal={openModal} price={selectedPrice} plan={plan} />
      )}

      <AddFreeUpgradePopUp
        isOpen={showDialog}
        closeTrialDialog={closeTrialDialog}
      />
    </div>
  );
};

export default PricePage;
