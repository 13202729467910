import React from "react";
import SignIn from "./SignIn";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import { Redirect } from "react-router-dom";
import UserProfile from "./UserProfile";
import SignUp from "./SignUp";
import Faq from "../faq/Faq";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const sessionRoutes = [
  {
    path: "/session/signin",
    component: SignIn,
    settings,
  },
  {
    path: "/session/signup",
    component: SignUp,
    settings,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
    settings,
  },
  {
    path: "/session/reset-password/:token",
    component: ResetPassword,
    settings,
  },
  {
    path: "/session/change-password",
    render: (props) =>
      localStorage.getItem("token") ? (
        <ChangePassword />
      ) : (
        <Redirect to="/session/signin" />
      ),
    settings,
  },
  {
    path: "/user/profile",
    render: (props) =>
      localStorage.getItem("token") ? (
        <UserProfile />
      ) : (
        <Redirect to="/session/signin" />
      ),
  },
  {
    path: "/session/404",
    component: NotFound,
    settings,
  },
  {
    path: "/faq",
    component: Faq,
    settings,
  },
  // {
  //   path: "/user-management/add-new-user",
  //   exact: true,
  //   render: (props) =>
  //      (
  //       <SignUp />
  //      )
  // },
];

export default sessionRoutes;
