import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const PropertyManage = EgretLoadable({
  loader: () => import("./PropertyManage")
});

const PropertyDetails = EgretLoadable({
  loader: () => import("./PropertyDetails")
});

const SelectProperty = EgretLoadable({
  loader: () => import("./SelectProperty")
})

const propertyRoutes = [
  {
    path: "/properties/manage",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <PropertyManage /> : <Redirect to='/session/signin' />
  },
  {
    path: "/properties/add",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <PropertyDetails /> : <Redirect to='/session/signin' />
  },
  {
    path: "/properties/select",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <SelectProperty /> : <Redirect to='/session/signin' />

  }
];

export default propertyRoutes;
