import React from "react";
import {
  Card,
  CardMedia,
  makeStyles,
  Typography,
  Divider,
  Container,
} from "@material-ui/core";
import SelectProperty from "./selectproperty.jpg";
import ManageProperties from "./manageproperties.jpg";
import AddProperty from "./addproperty.jpg";
import AddUrl from "./addurl.jpg";
import ContactUs from "./contactus.jpg";
import Gofind from "./gofind.jpg";
import Json from "./json.jpg";
import Pricing from "./pricing.jpg";
import Supportedurls from "./supportedurls.jpg";
import Updatepassword from "./updatepassword.jpg";
import Updateprofile from "./updateprofile.jpg";
import Manageurls from "./manageurls.jpg";

const useStyles = makeStyles({
  card: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 120,
    height: "50%",
    width: "70%",
  },
  cardMedia: {},
  container: {
    margin: 50,
  },
  h1: {
    marginTop: 5,
    marginBottom: 5,
  },
  h4: {
    marginTop: 8,
    marginBottom: 5,
  },
  mainCard: {
    marginTop: 50,
    marginBottom: 50,
  },
  p: {
    fontSize: 20,
    fontWeight: 200,
    marginBottom: 16,
  },
  json: {
    marginTop: 50,
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  jsoncard: {
    height: "10%",
    width: "80%",
    marginTop: 50,
    marginBottom: 50,
    marginLeft: 50,
  },
  jsontext: {
    fontSize: 20,
    fontWeight: 200,
    marginBottom: 12,
    marginLeft: 50,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  brand: {
    marginLeft: 120,
    marginTop: 30,
  },
  logo: {
    height: 36,
  },
  brandText: {
    fontWeight: 700,
    fontFamily: "Roboto,Helvetica Neue,sans-serif",
    boxSizing: "border-box",
    lineHeight: 1.5,
    fontSize: 22,
  },
});

const Faq = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.brand}>
        <img
          src="/assets/images/neonify_logo.png"
          alt="company-logo"
          className={classes.logo}
        />
      </div>

      <Container
        style={{
          paddingTop: "2rem",
          paddingBottom: "4rem",
          paddingLeft: "8rem",
          paddingRight: "6rem",
        }}
      >
        <Typography variant="h3">FAQs</Typography>
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h4}>
            What are properties?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={SelectProperty}
              alt="SelectProperty"
            />
          </Card>
          <Typography className={classes.p}>
            Segregated areas can be created inside the Neonify platform to
            effectively organise all active URLs and content feeds. Each area is
            assigned to a specific Property, e.g a particular website or
            application. When inside that property area, only information
            specific to that property is displayed.
          </Typography>
          <Typography className={classes.p}>
            After the user is signed in, the Select Property page is the first
            interaction page that the user will encounter. Here the user can
            select any of the properties he/her has previously created to
            proceed using the application. If you are a new user the Property
            list will be empty. To proceed you will need to create a property
            first which is explained in the next section.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h4}>
            How to manage property?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={ManageProperties}
              alt="ManageProperties"
            />
          </Card>
          <Typography className={classes.p}>
            You can manage your properties from the Magange Properties page. To
            navigate to the Manage Properties page click on Properties then
            Manage Properties from the sidebar menu.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div>
          <Typography variant="h4" className={classes.h4}>
            How to add a new property?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={AddProperty}
              alt="AddProperty"
            />
          </Card>
          <Typography className={classes.p}>
            You can create a new property area by clicking on the “Add a new
            Property” button on the top left of the page. You will be prompted
            to enter the Property details before saving. Once completed, your
            new property will immediately appear in your Manage Properties list.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to find new content?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Gofind}
              alt="Gofind"
            />
          </Card>
          <Typography className={classes.p}>
            You can use Neonify’s GoFind function, an integrated search engine
            with the full reach of google, to search the web and find relevant
            content for your properties. Enter a specific URL, if you already
            had one in mind, or search against key words or phrases. There are
            also several filter functions to narrow search results. Once
            relevant new content has been found using the GoFind function, you
            can submit a request to turn this location into a supported URL, if
            it is not already. Each search result will have an associated
            “Notify Admin” button. Simply click this button to alert the admin
            team, who will swiftly configure the URL for custom data feeds. If
            the URL is already supported by the Neonify platform, it can be
            deployed immediately.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            What are supported URLs?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Supportedurls}
              alt="Supportedurls"
            />
          </Card>
          <Typography className={classes.p}>
            All currently supported URLs are listed in the “Supported URLs”
            page, found on the navigation panel. This area can be searched and
            filtered similarly to the “Go Find” module.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            What is the Manage URLs Module?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Manageurls}
              alt="Manageurls"
            />
          </Card>
          <Typography className={classes.p}>
            The “Manage URLs” module is where users can Add, Modify or View Data
            from the scraped URLs.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to add a URL in Manage URLs?
          </Typography>
          <Card
            style={{
              height: "40%",
              width: "40%",
              marginLeft: 250,
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={AddUrl}
              alt="AddUrl"
            />
          </Card>
          <Typography className={classes.p}>
            To add a new URL, click on the “Add New URL” button on the top left
            of the screen. This will open a pop-up where you can add your URL
            and schedule a data updation interval. For e.g. if you select the
            “Daily” option your data for the URL will be updated once every 24
            hours.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to view generated data?
          </Typography>
          <div className={classes.json}>
            <Card
              style={{
                height: "70%",
                width: "70%",
                marginLeft: 150,
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={Json}
                alt="Json"
              />
            </Card>
            <Typography className={classes.jsontext}>
              To view the data generated by Neonify for your URL click on the
              eye icon under the “Data” column. This should open a pop-up that
              will show you a JSON format of the generated data.
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How much it will cost you?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Pricing}
              alt="Pricing"
            />
          </Card>
          <Typography className={classes.p}>
            Neonify packages are metered against both time and data usage. A
            notification will alert you as you approach either the expiry date
            or data limit of your current package, and you will be prompted to
            purchase a new one. To do this, click on the notification or use the
            navigation bar (left) to go to Pricing. Displayed here is an
            overview of each of the available packages. Select the most relevant
            package and you will be able to enter your payment details. Once
            completed, the new package will be available for use immediately.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to update your password?
          </Typography>
          <Card
            style={{
              height: "40%",
              width: "40%",
              marginLeft: 250,
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Updatepassword}
              alt="Updatepassword"
            />
          </Card>
          <Typography className={classes.p}>
            You can easily change your password by going to Profile then Change
            Password from the sidebar menu. This will take you to a form where
            you will have to enter your old password and the new password to
            proceed. After entering the details click on “Change Password” to
            continue.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to update your profile?
          </Typography>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={Updateprofile}
              alt="Updateprofile"
            />
          </Card>
          <Typography className={classes.p}>
            User can view his/her profile details by going to Profile then User
            Profile from the sidebar menu. This will take you to your profile
            where you can view all your details. If you wish to edit any of the
            details, it can be easily done so by clicking on the “Edit Profile”
            button on the top right.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.mainCard}>
          <Typography variant="h4" className={classes.h1}>
            How to contact us?
          </Typography>
          <Card
            style={{
              height: "60%",
              width: "60%",
              marginLeft: 200,
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <CardMedia
              className={classes.cardMedia}
              component="img"
              image={ContactUs}
              alt="ContactUs"
            />
          </Card>
          <Typography className={classes.p}>
            For any queries or feedback the user can always contact the Neonify
            team by clicking on the “Contact Us” option in the sidebar menu.
          </Typography>
        </div>
      </Container>
    </>
  );
};
export default Faq;
