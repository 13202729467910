import React, { useState } from "react";
import { Button, makeStyles, CardHeader } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { registerUser } from "../UserManagement/user-management.service";
import { Link, useHistory } from "react-router-dom";
import SessionLayout from "./SessionLayout";

const SignUp = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone_number: "",
    company_name: "",
    is_admin: false,
  });

  const { name, email, password, phone_number, company_name } = formData;

  const handleChange = (e, source) => {
    const { name, value } = e.target;
    if (source === "switch") {
      setFormData({ ...formData, is_admin: e.target.checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const response = await registerUser(formData);
    if (response.status === 200) {
      const message = response.data.message;
      toast.success(message);

      setFormData({
        ...formData,
        name: "",
        email: "",
        password: "",
        phone_number: "",
        company_name: "",
        is_admin: false,
      });

      history.push("/session/signin");
    } else {
      const errorMessage = response.data.message;
      toast.error(errorMessage);
    }
  };

  return (
    <SessionLayout>
      <div className="p-36 h-100">
        <CardHeader title="Sign Up" style={{ paddingLeft: 0 }} />
        <ValidatorForm onSubmit={handleFormSubmit}>
          <TextValidator
            className="mb-24 w-100"
            variant="outlined"
            label="Name"
            onChange={handleChange}
            type="text"
            name="name"
            value={name}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            className="mb-24 w-100"
            variant="outlined"
            label="Email"
            onChange={handleChange}
            type="email"
            name="email"
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
          />
          <TextValidator
            className="mb-16 w-100"
            label="Phone Number"
            variant="outlined"
            onChange={handleChange}
            name="phone_number"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            type="tel"
            value={phone_number}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            className="mb-16 w-100"
            label="Company Name"
            variant="outlined"
            onChange={handleChange}
            name="company_name"
            type="Text"
            value={company_name}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            className="mb-16 w-100"
            label="Password"
            variant="outlined"
            onChange={handleChange}
            name="password"
            type="password"
            value={password}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />

          <p>
            <span>
              Already have an account?{" "}
              <Link
                to="/session/signin"
                style={{ textDecoration: "underline" }}
              >
                Login
              </Link>
            </span>
          </p>
          <div className="flex flex-middle">
            <Button
              className="capitalize"
              variant="contained"
              color="primary"
              type="submit"
            >
              SignUp
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </SessionLayout>
  );
};

export default SignUp;
