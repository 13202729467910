import React, { useState, useEffect } from 'react'
import {
    Button,
    Card,
    FormControl,
    InputLabel,
    Input,
} from "@material-ui/core";
import { updateLoggedInUserInfo, getLoggedInUserInfo } from './userService';
import { toast } from 'react-toastify';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useContext } from 'react';
import AppContext from 'app/appContext';


const UserProfile = () => {

    const { loggedInUser, setUser } = useContext(AppContext)

    const [loggedInUserData, setloggedInUserData] = useState({
        name: '',
        email: '',
        company_name: '',
        phone_number: '',
        avatar: loggedInUser.avatar
    })

    const [editDetailsDisabled, setEditDetailsDisabled] = useState(true)

    const fetchLoggedInUserInformation = async () => {
        const response = await getLoggedInUserInfo()
        if (response.status === 200) {
            const { name, email, company_name, phone_number } = response.data.user
            setloggedInUserData({ ...loggedInUserData, name, email, company_name, phone_number })
        } else {
            const errorMessage = response.data.message;
            toast.error(errorMessage)
        }
    }

    useEffect(() => {
        fetchLoggedInUserInformation()
    }, [])

    const { name, email, company_name, phone_number } = loggedInUserData;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setloggedInUserData({ ...loggedInUserData, [name]: value })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault()
        const response = await updateLoggedInUserInfo(loggedInUserData)
        if (response.status === 200) {
            toast.success(response.data.message)
            setEditDetailsDisabled(!editDetailsDisabled)
            setUser(loggedInUserData)
        }
        else {
            const errorMessage = response.data.message;
            toast.error(errorMessage)
        }
    }
    return (
        <div className="invoice-viewer py-16">
            <div className="viewer_actions px-16 mb-20 flex flex-middle flex-end">
                <div>
                    {editDetailsDisabled === true ? (
                        <Button
                            className="mr-16 py-8"
                            variant="contained"
                            color="primary"
                            onClick={() => setEditDetailsDisabled(false)}
                        >
                            Edit Profile
                        </Button>
                    ) : (
                        null
                    )}
                </div>
            </div>

            <div id="print-area" style={{ margin: '2rem' }} >
                <Card className="w-100 overflow-auto" elevation={6} style={{ padding: '2rem' }} >
                    <ValidatorForm onSubmit={onFormSubmit}>

                        <FormControl disabled={editDetailsDisabled} fullWidth={true} className="mb-16">
                            <InputLabel><strong>Name</strong></InputLabel>
                            <Input value={name} onChange={handleOnChange} name="name" />
                        </FormControl>

                        <FormControl disabled={editDetailsDisabled} fullWidth={true} className="mb-16">
                            <InputLabel><strong>Email address</strong></InputLabel>
                            <Input value={email} onChange={handleOnChange} name="email" />
                        </FormControl>

                        <FormControl disabled={editDetailsDisabled} fullWidth={true} className="mb-16">
                            <InputLabel><strong>Company Name</strong></InputLabel>
                            <Input value={company_name} onChange={handleOnChange} name="company_name" />
                        </FormControl>

                        <FormControl disabled={editDetailsDisabled} fullWidth={true} className="mb-16">
                            <InputLabel><strong>Phone Number</strong></InputLabel>
                            <Input value={phone_number} onChange={handleOnChange} name="phone_number" />
                        </FormControl>

                        <Button
                            className="mr-16 py-8"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={editDetailsDisabled}
                        >
                            Update Profile
                        </Button>


                    </ValidatorForm>
                </Card>
            </div>
        </div>
    )
}

export default UserProfile