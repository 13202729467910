import PricePage from "./Prices";

const PriceRoutes = [
    {
        path: '/prices',
        exact: true,
        component: PricePage
    }
]

export default PriceRoutes