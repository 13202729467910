import "../fake-db";
import "../styles/_app.scss";
import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import EgretLayout from "./EgretLayout/EgretLayout";
import setAuthToken from "./utils/setAuthToken";
import { toast } from "react-toastify";
import axios from "axios";
import Config from "../app/config/Config";
import { tryCatchBlockify } from "utils/utils";
const { serverUrl } = Config;

const stripePromise = loadStripe(Config.stripePublicKey, {
  stripeAccount: "acct_1K6XQqKZzcCyY2NP",
});

const App = () => {
  const [loggedInUser, setUser] = useState({});

  const fetchUserDetails = async () => {
    const { data: response, error: err } = await tryCatchBlockify(
      axios.get(`${serverUrl}/api/users`)
    );

    if (response.status === 200) {
      setUser(response.data.user);
    } else if (err.response.status === 401) {
      localStorage.removeItem("token");
      history.push({
        pathname: "/session/signin",
      });
    } else {
      toast.error("Not able to get User Details");
    }
  };

  useEffect(() => {
    if (localStorage.token) {
      fetchUserDetails();
    }
  }, []);

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  return (
    <AppContext.Provider value={{ routes, loggedInUser, setUser }}>
      <Provider store={Store}>
        <EgretTheme>
          <Elements stripe={stripePromise}>
            <Router history={history}>
              <EgretLayout />
            </Router>
          </Elements>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
