import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";


const SupportedURL = EgretLoadable({
    loader: () => import("./SupportedURL")
});

const supportedURLS = [
    {
        path: "/urls/supported",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <SupportedURL /> : <Redirect to='/session/signin' />
    },
]

export default supportedURLS