import React, { Component } from "react";
import { Button } from "@material-ui/core";

const NotFound = (props) => {

  const backToDashboardBasedOnToken = () => {
    localStorage.getItem('token') ? (props.history.push("/properties/manage")) : (props.history.push("/"))
  }

  return (
    <div className="flex flex-center flex-middle w-100 h-100vh">
      <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
        <img className="mb-32" src="/assets/images/illustrations/404.svg" alt="" />
        <Button
          className="capitalize"
          variant="contained"
          color="primary"
          onClick={backToDashboardBasedOnToken}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );

}

export default NotFound;
